import {
  BASE_FONT_RELATIVE,
  GRID,
  borderShadows,
  borderWidths,
  boxShadows,
  fontSizes,
  fontWeights,
  globalThemeColors,
  letterSpacings,
  lineHeights,
  opacities,
  outlines,
  radii,
  transitions,
} from './tokens';
import * as badgeTokens from '../../components/Badge/tokens';
import * as buttonTokens from '../../components/Button/tokens';
import * as headerTokens from '../../components/DesktopHeader/tokens';
import * as expandableTokens from '../../components/Expandable/tokens';
import * as messagebarTokens from '../../components/MessageBar/tokens';
import * as navlinkOnColorTokens from '../../components/NavLink/tokens';
import * as progressIndicatorTokens from '../../components/ProgressIndicator/tokens';
import * as ribbonTokens from '../../components/Ribbon/tokens';
import { Fonts } from '../../types';
import { getFontFaces } from '../../util';

const fonts: Fonts = [
  ['Boing', 700, 'boingBold.woff2', 'woff2'],
  ['Graphik', 400, 'graphikRegular.woff2', 'woff2'],
  ['Graphik', 700, 'graphikBold.woff2', 'woff2'],
];

const bodyTypography = {
  bodyXL: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.BodyXL,
    lineHeight: lineHeights['7'],
  },
  bodyL: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.BodyL,
    lineHeight: lineHeights['7'],
  },
  bodyM: {
    fontSize: fontSizes.BodyM,
    lineHeight: lineHeights['6'],
  },
  bodyS: {
    fontSize: fontSizes.BodyS,
    lineHeight: lineHeights['5'],
  },
  bodyXS: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.BodyXS,
    lineHeight: lineHeights['4'],
  },
  quoteM: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes['32'],
    lineHeight: lineHeights['9'],
  },
  quoteS: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes['24'],
    lineHeight: lineHeights['7'],
  },
} as const;

const headingTypography = {
  heading3xl: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes['3XL'],
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['20'],
  },

  heading2xl: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes['2XL'],
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['16'],
  },
  headingXl: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.XL,
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['12'],
  },
  headingL: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.L,
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['10'],
  },
  headingM: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.M,
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['9'],
  },
  headingS: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.S,
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['7'],
  },
  headingXs: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes.XS,
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['6'],
  },
  heading2xs: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes['2XS'],
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['6'],
  },
  heading3xs: {
    letterSpacing: letterSpacings.normal,
    fontSize: fontSizes['3XS'],
    fontWeight: fontWeights.headingRegular,
    lineHeight: lineHeights['5'],
  },
} as const;

const typography = {
  ...bodyTypography,
  ...headingTypography,
};

/**
 * TODO: Add iconSizes to sizes scale maybe?
 * iconSmall, iconMedium, iconLarge
 */
const iconSizes = {
  small: '1.6rem',
  medium: '2.4rem',
  large: '3.2rem',
  extraLarge: '6.4rem',
  illustrationSmall: '4rem',
  illustrationMedium: '6rem',
  illustrationLarge: '8rem',
};

const theme = {
  colors: {
    ...globalThemeColors,
    ...buttonTokens.oxxioButtonColors,
    ...expandableTokens.oxxioExpandableTokens,
    ...headerTokens.oxxioHeaderColors,
    ...messagebarTokens.oxxioMessagebarColors,
    ...navlinkOnColorTokens.oxxioNavlinkOncolorColors,
    ...progressIndicatorTokens.oxxioProgressIndicatorTokensColors,
    ...ribbonTokens.oxxioRibbonColors,
    ...badgeTokens.oxxioBadgeColors,
  },
  shadows: {
    ...boxShadows,
    ...borderShadows,
  },
  outlines: {
    ...outlines,
  },
  fonts: {
    body: 'Graphik, sans-serif',
    heading: 'Boing, sans-serif',
  },
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  space: {
    0: '0px',
    1: 1 * GRID + 'px',
    2: 2 * GRID + 'px',
    3: 3 * GRID + 'px',
    4: 4 * GRID + 'px',
    5: 5 * GRID + 'px',
    6: 6 * GRID + 'px',
    7: 7 * GRID + 'px',
    8: 8 * GRID + 'px',
    10: 10 * GRID + 'px',
    12: 12 * GRID + 'px',
    16: 16 * GRID + 'px',
    24: 24 * GRID + 'px',
    32: 32 * GRID + 'px',
  },
  sizes: {
    1: '768px',
    2: '1024px',
    3: '1220px',
    4: '1432px',
    5: '1680px',
    buttonMinWidth: '80px',
    targetMinWidth: '44px',
    targetMinHeight: '44px',
    inputMinHeight: '48px',
  },
  borderWidths: {
    ...borderWidths,
    ...buttonTokens.oxxioButtonBorderWidths,
    ...headerTokens.oxxioHeaderDividerHeight,
  },
  radii: {
    ...radii,
    ...buttonTokens.oxxioButtonBorderRadii,
  },
  transitions,
  opacities,
  display: {
    ...buttonTokens.oxxioButtonChevronDisplay,
  },
} as const;

const globalStyles = {
  '@font-face': getFontFaces(fonts),
  '@media (prefers-reduced-motion: no-preference)': {
    ':root': {
      'scroll-behavior': 'smooth',
    },
  },
  ':root': {
    fontSize: BASE_FONT_RELATIVE,
  },
  body: {
    backgroundColor: theme.colors.backgroundSecondary,
    color: theme.colors.textPrimary,
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes.BodyM,
    fontWeight: theme.fontWeights.bodyRegular,
    lineHeight: theme.lineHeights['6'],
    margin: 0,
  },

  'a, button': {
    '&:focus': {
      outline: theme.outlines.outlineFocus,
    },
    '@supports selector(:focus-visible)': {
      '&:focus': {
        outline: 'none',
      },
      '&:focus-visible': {
        outline: theme.outlines.outlineFocus,
      },
    },
  },

  'button, input, select, textarea': {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.bodyRegular,
  },

  '.seamly-placeholder': {
    zIndex: 2,
    position: 'relative',
    pointerEvents: 'all',
  },
};

export { BASE_FONT_RELATIVE, globalStyles, iconSizes, theme, typography };
